import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/feazynet_story_large.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="Customer story: How This Call Center Manager No Longer Spreaded Work Across Different Apps"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How This Call Center Manager No Longer Spreaded Work Across
                    Different Apps
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section bg-image py-28 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    Jerry Corkery—manager of the call center for an Internet
                    Service Provider—has a lot of responsibilities to take at
                    work—managing employees, handling customers' complaints, and
                    taking care of other admin work. Worst of all, he used
                    spreadsheets, chat apps, and emails to work with his
                    employees. Time was wasted, and everyone was distracted.
                    Jerry knew it wasn't the best workflow, so he gave Camelo a
                    try. And he was hooked.
                  </p>
                  <h4 className="pt-8 pb-4">A tough place to work for</h4>
                  <p className="gr-text-8 text-black">
                    Jerry is a manager of a call center giving customer support
                    for an ISP (Internet Service Provider) in Delaware. It's one
                    of the toughest and stressful places to work for. Each call
                    center employee has to deal with hundreds of calls every
                    day.
                  </p>
                  <p className="gr-text-8 text-black">
                    "As customers' demands and expectations have become higher,
                    it's getting harder to satisfy everyone. Both my employees
                    and I have to be prepared for the worst-case
                    scenario—customers cursing, publicizing minor mistakes,
                    demanding unreasonable things."
                  </p>
                  <p className="gr-text-8 text-black">
                    Employees come in and out all the time because they can't
                    stand the pressure. The employee turnover rate is almost
                    always high. Recruitment and retention have always given
                    Jerry headaches and taken hours of his day.
                  </p>

                  <h4 className="pt-8 pb-4">
                    A lot of responsibilities to take
                  </h4>
                  <p className="gr-text-8 text-black">
                    The call center needs at least 6 employees to accommodate
                    customers' needs each day. It has about 10 members in total,
                    working flexible shifts, making it even harder for Jerry to
                    manage. He has to deal with difficult customers, help his
                    staff, as well as managing other admin work.
                  </p>

                  <h4 className="pt-8 pb-4">
                    The distracting, time-consuming back-and-forth combination
                  </h4>
                  <p className="gr-text-8 text-black">
                    Jerry arranges a flexible schedule in which his staff could
                    have some time off every few days. He believes it's a way
                    for his employees to recover and unplug from work.
                  </p>
                  <p className="gr-text-8 text-black">
                    At first, they were a small team of 3 people, so he created
                    work schedule in an online spreadsheet. The link was shared
                    to all of the team members, so they could access it anywhere
                    and anytime they wanted.
                  </p>
                  <p className="gr-text-8 text-black">
                    They mostly communicated through emails and WhatsApp. Emails
                    were for important documents such as time-off requests and
                    company announcements. People had to wait longer for a
                    reply, and took longer to write proper emails. WhatsApp was
                    for quick messages, quick check-ins, and memes. The problem
                    was that messages often drifted and some employees missed
                    important information.
                  </p>
                  <p className="gr-text-8 text-black">
                    The worst thing combined was they all had to jump back and
                    forth between different apps to get the job done. Because
                    new staff came in all the time, Jerry spent a huge amount of
                    time trying to explain the workflow and tools.
                  </p>

                  <h4 className="pt-8 pb-4">A better solution for everyone</h4>
                  <p className="gr-text-8 text-black">
                    Jerry decided to give Camelo a try. He first used the free
                    Basic plan which includes basic features needed for
                    scheduling and tracking employees. "It was quite surprising
                    because I didn't know a free option could be that good."
                  </p>
                  <p className="gr-text-8 text-black">
                    As Jerry wanted more advanced features such as planning
                    schedules weeks in advance and gaining unlimited access to
                    historical timesheets, he decided to upgrade to the Pro
                    plan. There he discovered he could have more storage space
                    and prioritized support, making the whole scheduling and
                    admin process easier.
                  </p>

                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "I can just drop my staff a message on the app whenever I
                      want. All of them have a smartphone, so they can receive
                      notifications immediately. Thanks to better and
                      predictable scheduling, my staff know what they're dealing
                      with, and be well-prepared for their shifts."
                    </p>
                    <footer className="blockquote-footer">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="gr-text-10 gr-color-blackish-blue-opacity-8">
                          <div>
                            Jerry Corkery
                            <span> (Manager)</span>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>

                  <p className="gr-text-8 text-black">
                    Because work has become more overwhelming for Jerry, he's
                    hired an assistant manager who helps him with scheduling and
                    assigning tasks. Jerry shares, "I grant him admin permission
                    on the app, so only he can make changes to the schedule. A
                    lot has been taken off my shoulders."
                  </p>
                  <p className="gr-text-8 text-black">
                    Jerry now has more time to care about his employees' needs
                    and concerns instead of constantly hiring, training, or
                    signing resignation forms of employees.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      "To be honest, with all the time and effort saved, I've
                      gained more than I paid for the software."
                    </p>
                    <footer className="blockquote-footer">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="gr-text-10 gr-color-blackish-blue-opacity-8">
                          <div>
                            Jerry Corkery
                            <span> (Manager)</span>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
